import { useState } from "react"
import styled from "styled-components"
import { useDispatch } from 'react-redux';
import { openMainModal } from './../../store/modalSlice';
// Menu components
import { ButtonsContainer, NavMenu } from "./components"

const MenuContainer = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1500;
  box-sizing: border-box;
`

const MoveContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 10px;
  box-sizing: border-box;
`
export const Menu = () => {
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(true)

  const closeMenu = () => {
    window.initsound();
    setMenuIsOpen(true);
  }

  window.closeMenuWindow = function () {
    window.initsound();
    setMenuIsOpen(true);
  }

  const buttons = {
    buttonsContainer: {
      homeButton: () => {
        //window.NavigateTo('home');
        window.ExitAuditorioMenu("home", "");
      },
      soundButton: () => {
        window.ToggleSound();
      },
      menuButton: () => {
        setMenuIsOpen((currentStatus) => !currentStatus);
      },
      hamburgerButton: () => {
        setMenuIsOpen((currentStatus) => !currentStatus);
      },
    },
    navMenu: [
      {
        name: "Espaço Centenário",
        redirectUrl: "#",
        func: () => {
          window.ExitAuditorioMenu("centenario", "espacocentenario");
          // if(window.launchVideoCompleted ){
          //   closeMenu();
          //   window.NavigateTo("centenario");
          //   window.registerPinCount("espacocentenario");
          //   // dispatch(openMainModal('centenario'))
          // }else{
          //   window.OpenModalExitAuditorio();
          // }
        },
      },
      {
        name: "Produtos / Máquinas",
        redirectUrl: "#",
        func: () => {
          window.ExitAuditorioMenu("maquinas", "");
          // if(window.launchVideoCompleted){
          //   closeMenu();
          //   window.NavigateTo("maquinas");
          //   // dispatch(openMainModal(''))
          // }else{
          //   window.OpenModalExitAuditorio();
          // }          
        },
      }, 
      {
        name: "Soluções Dantotsu",
        redirectUrl: "#",
        func: () => {
          window.ExitAuditorioMenu("servicosdantosu", "servicos");
          // if(window.launchVideoCompleted){
          //   closeMenu();
          //   window.NavigateTo("servicosdantosu");
          //   window.registerPinCount("servicos");
          // }else{
          //   window.OpenModalExitAuditorio();
          // }          
        },
      },  
      {
        name: "Auditório ",
        redirectUrl: "#",
        func: () => {
          window.ExitAuditorioMenu("auditorio", "auditorio");
          // if(window.launchVideoCompleted){
          //   closeMenu();
          //   window.NavigateTo("auditorio");
          //   window.registerPinCount("auditorio");
          // }else{
          //   window.OpenModalExitAuditorio();
          // }          
        },
      }, 
      {
        name: "Komatsu Shop",
        redirectUrl: "#",
        func: () => {
          window.ExitAuditorioMenu("loja", "shop");
          // if(window.launchVideoCompleted){
          //   closeMenu();
          //   window.NavigateTo("loja");
          //   window.registerPinCount("shop");
          // }else{
          //   window.OpenModalExitAuditorio();
          // }          
        },
      },  
      {
        name: "Sala de Reunião",
        redirectUrl: "#",
        func: () => {
          window.ExitAuditorioMenu("reuniao", "reuniao");
          // if(window.launchVideoCompleted){
          //   closeMenu();
          //   window.NavigateTo("reuniao");
          //   window.registerPinCount("reuniao");
          // }else{
          //   window.OpenModalExitAuditorio();
          // }
          
        },
      },
      {
        name: "Tour pelo Showroom",
        redirectUrl: "#",
        func: () => {
          window.registerPinCount("tour");
          window.OpenVideoPlayer('https://player.vimeo.com/video/652210012');
          closeMenu();
        },
      },
    ],
  }

  return (
    <MenuContainer>
      <MoveContainer className='centralizer' isOpen={menuIsOpen}>
        <ButtonsContainer
          menuIsOpen={menuIsOpen}
          buttonsFunctionList={buttons.buttonsContainer}
        />
        <NavMenu
          menuIsOpen={menuIsOpen}
          buttonsFunctionList={buttons.navMenu}
        />
      </MoveContainer>
    </MenuContainer>
  )
}
