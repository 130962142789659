import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`flex-basis: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;

    h6 {
        font-size: 16px;
    }
    p {
        font-size: 12px;
    }
    @media (max-width:768px){
        h6 {
            font-size: 12px;
        }
        p {
            font-size: 10px;
        }   
    }
    `}
`;