import { useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from 'styled-components';
import { selectCVTDataByRef, selectCVTRef } from "../../store/creatingValueTogetherSlice";
import * as Styled from "./styles";
import { ContactContent } from "../../components/ContactContent";
import { removeNonNumeric, whatsappHrefString } from "../../services/utils";
import { IconButton } from "../../components/IconButton";
import { AiOutlineInstagram, AiFillYoutube, AiFillFilePdf } from 'react-icons/ai';
import { FaFacebook, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { TextContainer } from "../../components/TextContainer";

import { PDFViewer } from '../../components/PDFViewer';
import ModalBootstrapReuse from '../../components/MainModal/ModalBootstrapReuse';
import { SocialMidiaGroup } from "../../components/SocialMidiaGroup";


export const ModalCreatingValueTogether = ({ children }) => {
  const theme = useTheme();
  const data = useSelector(selectCVTDataByRef);
  const ref = useSelector(selectCVTRef);

  const [pdf, setPdf] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  const [toggle, setToggle] = useState(false);
    
  const openModal = (arquivo, nomeCatalogo, referencia) => {
    setPdf(`https://dashboard.komatsushowroom.com.br/img/arquivo/${arquivo}`);
    setPdfName(nomeCatalogo);
    setToggle(true);
    window.registerCount(`pdf_${referencia}`);
  }

  const getIconByRede = (rede) => {
    switch(rede.toLowerCase()){
      case 'facebook':
        return <FaFacebook fill={theme.mainColor} size={40}  />
      case 'instagram':
        return <AiOutlineInstagram fill={theme.mainColor} size={40}  />
      case 'linkedin':
        return <FaLinkedinIn fill={theme.mainColor} size={40}  />
      case 'youtube':
        return <AiFillYoutube fill={theme.mainColor} size={40}  />
      case 'whatsapp':
        return <FaWhatsapp fill={theme.mainColor} size={40}  />
      default:
        return null;
    }
  }

  const getContentByRef = () => {
    switch(ref){
      case 'treinamento1':
        return <TextContainer title={data.titulo} body={data.texto}/>
      case 'comunicacao1':
        return <>
          <h2>{data.titulo}</h2>
          {data.texto?<p>{data.texto}</p>:null}
          <SocialMidiaGroup data={data.redes}/>
        </>
      case 'comunicacao2':
        return <>
          <h2>{data.titulo}</h2>
          {data.texto?<p>{data.texto}</p>:null}
          <Styled.ComunicacaoBlock>
          {data.contatos ? data.contatos.map((contato, i) => <ContactContent key={i} 
            emperesa={contato.empresa}
            nome={contato.nome} 
            tel={contato.numero}
            email={contato.email}
          /> ) : null}
          </Styled.ComunicacaoBlock>
        </>
      case 'komtrax3':
      case 'simuladores2':
      case 'maxifleet3':
      case 'pmp1':
      case 'pmp2':
        return <>
          <PDFViewer pdf={`https://dashboard.komatsushowroom.com.br/img/arquivo/${data.arquivo}`} fileName={data.nomeArquivo}/>
        </>
      default:
        return <>
          <h2>{data.titulo}</h2>
          {data.texto?<p>{data.texto}</p>:null}
          {data.redes ? data.redes.map((rede, i) => <IconButton key={i} 
          link={rede.link}
          iconComponent={getIconByRede(rede.rede)} 
          text={rede.textoBotao} /> ) : <IconButton text={data.nomeArquivo} link={`https://dashboard.komatsushowroom.com.br/img/arquivo/${data.arquivo}`} iconComponent={<AiFillFilePdf fill={theme.mainColor} size={40} />} />}
        </>
    }
  }

  return (
    <Styled.Container>
      {ref ? getContentByRef() : null }
    </Styled.Container>
  );
};