import * as Styled from './styles';
import { codareaToSigla } from '../../../../services/estadosFn';
export const DistribuidorDados = ({ estado, bairro, cep, cidade, email, endereco, nome, razao_social, site, tel}) => {
    return (
        <Styled.Container>
            <h6>{nome}</h6>
            <p>{endereco} {bairro}</p>
            <p>CEP: {cep}</p>
            <p>{cidade} - {codareaToSigla(estado)}</p>
            <p>{tel}</p>
        </Styled.Container>
    );
};