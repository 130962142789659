import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        overflow-y: auto;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: column;
        align-items:center;
        gap: 40px;
        padding: 25px;
        overflow-y: auto;
    h2 {
        margin: 0 auto;
        text-align: center;
    }
    p {
        margin-bottom: 0;
    }
    @media screen and (max-width: 768px){
        gap: 20px;
        padding: 20px 0px;
        h2{
            font-size: 1.5rem;
        }
    }
    `}
`;

export const BlockSocialMidias = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 15px 0;
    ` }
`

export const Contact = styled.ul`
    ${({ theme }) => css`
        
        flex-basis: 40%;
        flex-grow: 2;
        max-width: 443px;
        align-items: center;
        color: #919191;
        font-weight: bold;
        font-size: 22px;
    @media screen and (max-width: 550px){
        font-size: 16px;
    }
    div {
        display: flex;
        align-items: center;
        flex-grow: 2;
        justify-content: space-around;
        h5 {
            text-align: center;
        }
        @media (max-width: 550px){
            flex-wrap: wrap;
        }
    }
    `}
`

export const ComunicacaoBlock = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 6px 0;
        padding: 0 10px;   
    `}
`