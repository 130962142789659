import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectModalName, closeMainModal, selectToggle, setSpecialSize } from '../../store/modalSlice';

import { ModalWhatsapp } from '../../templates/ModalWhatsapp';
import { ModalLinkExterno } from '../../templates/ModalLinkExterno';

// import ModalVideos from '../ModalVideo/ModalVideos';
import { ModalDestribuidores } from '../../templates/ModalDestribuidores';
import { ModalTextoContatos } from '../../templates/ModalTextoContatos';
import { ModalRent } from '../../templates/ModalRent';
import { ModalCatalogos } from '../../templates/ModalCatalogos';
import { ModalPalavraPresidente } from '../../templates/ModalPalavraPresidente';
import { ModalLinks } from '../../templates/ModalLinks';
import ModalCentenario from '../../templates/ModalCentenario';
import { ModalCatalogoPDFs } from '../../templates/ModalCatalogoPDFs';
import { ModalMaquinasImageGallery } from '../../templates/ModalMaquinasImageGallery';
import { ModalShopFullImage } from '../../templates/ModalShopFullImage';
import { ModalMaquina } from '../../templates/ModalMaquina';
// import ModalTexto from '../ModalTexto/ModalTexto';
// import ModalCatalogosMaquinas from '../ModalCatalogosMaquinas/ModalCatalogosMaquinas';
import { ModalCatalogosMaquinas } from '../../templates/ModalCatalogosMaquinas';
import { ModalCreatingValueTogether } from '../../templates/ModalCreatingValueTogether';
import { ModalTotemAuditorioVideos } from '../../templates/ModalTotemAuditorioVideos';
import ModalBootstrap from './ModalBootstrap';
import { ModalCabecotes } from '../../templates/ModalCabecotes';
import { ModalTimeline } from '../../templates/ModalTimeline';
import { ModalAluguelNovosSeminovos } from '../../templates/ModalAluguelNovosSeminovos';
import { ModalSocialMidias} from '../../templates/ModalSocialMidias';
import { ModalVideos } from '../../templates/ModalVideos';
import { ModalTexto } from '../../templates/ModalTexto';
// Passar a rolagem para fora do modal, rolando a tela inteira

export const MainModal = ({ children }) => {
    const dispatch = useDispatch();
    const toggle = useSelector(selectToggle);
    const name = useSelector(selectModalName);

    useEffect(() => {
        if(name == 'modal_distribuidores'){
            dispatch(setSpecialSize(true));
        } else {
            dispatch(setSpecialSize(false));
        }
    },[toggle]);
    
    const selectModal = () => {
        switch(name){
            // OK
            case 'centenario':
                return <ModalCentenario />
            //  todo: tesponsivo nos botoes
            // case 'modal_catalogos':
            //     return <ModalCatalogos />
            // OK
            case 'palavra_presidente':
                return <ModalPalavraPresidente />
            //  todo: tesponsivo nos botoes
            // AQUI TEM PDF
            case 'links_centenario':
                return <ModalLinks />
                // Modal com problema - verificar
            // case 'redes_sociais':
            //     return <ModalSocialMidias />
                // Modal com problema - verificar
            // case 'videos_centenario':
            //     return <ModalVideosContent />
                // 
            // OK
            case 'whatsapp':
                return <ModalWhatsapp />
            // OK porem sem conteudo
            case 'modal_rent':
                return <ModalRent />
            // OK
            // case 'modal_options':
            //     return <ModalOptions />
                //  Modal sem conteudo - depende do conteudo
            case 'modal_videos': 
                return <ModalVideos />
            // OK porem com conteudos LOREM
            case 'modal_texto_contatos':
                return <ModalTextoContatos />
            // OK porem com conteudos LOREM
            case 'modal_links_externo':
                return <ModalLinkExterno />

            // OK - MODAL DAS HQs
            case 'modal_pdfs_catalogo':
                return <ModalCatalogoPDFs />
            
            // OK
            case 'modal_galeria_maquinas':
                return <ModalMaquinasImageGallery />
            // OK
            case 'modal_shop_full_image': 
                return <ModalShopFullImage /> 
            // OK
            case 'modal_maquina': 
                return <ModalMaquina /> 

                // sem conteudo
            case 'modal_texto':
                return <ModalTexto />
                // sem conteudo
            case 'modal_catalogos_maquinas': 
                return <ModalCatalogosMaquinas />
            // ok
            case 'modal_distribuidores':
                return <ModalDestribuidores />
            // ok
            case 'modal_cvt':
                return <ModalCreatingValueTogether />
            // ok - ESSE É O MODAL COM A GALERIA DE VIDEOS
            case 'modal_totem_galeria':
                return <ModalTotemAuditorioVideos />
            // OK - MODAL DEPDENDE DE REFERENCIAS
            case 'modal_aluguel_novos_seminovos':
                return <ModalAluguelNovosSeminovos />
                // sem conteudo
            case 'modal_cabecotes':
                return <ModalCabecotes />
            case 'modal_timeline':
                return <ModalTimeline />
            default:
            return children
        }
    }
    
    const getResponsiveStyle = () => {
        const { width } = window.visualViewport;
        switch (width < 564) {
            case true :
                return {
                    width: "90%",
                    padding: "unset",
                    background: "#F9F9F9",
                    borderRadius: '24px',
                    zIndex: 1600,
                    position: 'absolute',
                    top: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                    maxWidth: '1200px',
                    overflowY:'none',
                    margin: '0px'
                }
                break;
                
                default:
                    return {
                        width: "90%",
                        padding: "unset",
                        background: "#F9F9F9",
                        borderRadius: '24px',
                        zIndex: 1600,
                        position: 'absolute',
                        top: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto%',
                        maxWidth: '1200px',
                        overflowY:'none'
                    }
                break;
        }
        
    }

    return <ModalBootstrap
    show={toggle}
    onHideFn={() => dispatch(closeMainModal())} >
        {selectModal()}
    </ModalBootstrap>
}