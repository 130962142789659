// components
import { useEffect } from "react"
import { Menu } from "./components"
import { MainModal } from "./components/MainModal/MainModal"
import axios from 'axios'
import { setDados } from './store/distribuidoresSlice';
import { useDispatch } from "react-redux";
import WindowFunctions from "./services/WindowFunctions";
import { setECData } from './store/espacoCentenarioSlice';
import { setKomatsuShopData } from './store/komatsuShopSlice';
import { setMaquinasData } from "./store/maquinasSlice";
import { setAuditoriosDados } from "./store/auditorioSlice";
import { setCreatingValueTogetherData } from './store/creatingValueTogetherSlice';
import { setAllItems } from './store/timelineSlice';
import ModalBootstrap from "./components/MainModal/ModalBootstrap";
import { ModalExternoPDF } from "./templates/ModalExternoPDF";
import { ModalVideoExterno } from "./templates/ModalVideoExterno";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    axios.get('https://dashboard.komatsushowroom.com.br/komatsu.json')
      .then(res => { 
        dispatch(setAllItems(res.data.espaco_centenario.linha_do_tempo))
        dispatch(setKomatsuShopData(res.data.komatsu_shop))
        dispatch(setECData(res.data.espaco_centenario))
        dispatch(setMaquinasData(res.data.maquinas))
        dispatch(setAuditoriosDados(res.data.auditorio))
        dispatch(setDados(res.data.estado))
        dispatch(setCreatingValueTogetherData(res.data.creating_value_together))
      })
      .catch(console.warn)
  },[])
  return (
    <div>
      <WindowFunctions>
        <Menu />
        <MainModal />
        <ModalVideoExterno />
        <ModalExternoPDF />
      </WindowFunctions >
    </div>
  )
}

export default App
