import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modalSlice';
import distribuidoresReducer from './distribuidoresSlice';
import urlAuthReducer from './urlAuthSlice';
import espacoCentenarioReducer from './espacoCentenarioSlice';
import komatsuShopReducer from './komatsuShopSlice';
import maquinasReducer from './maquinasSlice';
import modalVideoReducer from './videoModalSlice';
import auditorioReducer from './auditorioSlice';
import salaReuniaoReducer from './salaReuniaoSlice';
import creatingValueTogetherReducer from './creatingValueTogetherSlice';
import externalModalReducer from './externalModalSlice';
import timelineReducer from './timelineSlice';
import videoModalExternoReducer from './videoModalExternoSlice';
export const store = configureStore({
    reducer: { 
        modal: modalReducer,
        distribuidores: distribuidoresReducer,
        urlAuth: urlAuthReducer,
        espaco_centenario: espacoCentenarioReducer,
        komatsu_shop: komatsuShopReducer,
        maquinas: maquinasReducer,
        modalVideo: modalVideoReducer,
        auditorio: auditorioReducer,
        sala_reuniao: salaReuniaoReducer,
        creating_value_together: creatingValueTogetherReducer,
        external_modal: externalModalReducer,
        timeline: timelineReducer,
        modal_video_externo: videoModalExternoReducer
    }
})